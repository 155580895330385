import React from "react";
import {
    LineChart,
    Line,
    AreaChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Area,
    ComposedChart,
    PieChart,
    Pie,
    BarChart,
    Bar,
    ResponsiveContainer,
  } from "recharts";

const ResponsiveChart = (props)=>{

    return (
        <>
         <div
          style={{
            width: "95%",
            padding: ".5rem",
            margin: ".5rem",
            backgroundColor: "iceblue",
            border: "1px solid black",
            borderRadius: "10px",
          }}
        >
          <h2>{props.heading}</h2>
          <div style={{ overflowX: "scroll" }}>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={props.data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis dataKey="name" />
                <YAxis type="number" domain={[100, "dataMax"]} />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey={props.dataKey} stroke="#8884d8" />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        </>
    )

}

export default ResponsiveChart;