import React from 'react';
import { Table } from 'react-bootstrap';

const AverageSalesDataForDate = (props) => {

  if (!props.sales || props.sales.length === 0) {
    return <div>Loading...</div>; // Handle the case when sales data is not available
  }

  // Function to calculate average sales per day for each item
  const calculateAverage = (itemData) => {
    const totalSales = itemData.reduce((sum, record) => sum + record, 0);
    const totalDays = itemData.length;
    return (totalSales / totalDays).toFixed(2);
  };

  // Extract item sales from props
  const chaiAverage = calculateAverage(props.sales.map(sale => sale.chaiTotal));
  const bottleAverage = calculateAverage(props.sales.map(sale => sale.bottleAmount));
  const biscuitAverage = calculateAverage(props.sales.map(sale => sale.biscuitAmount));
  const totalSaleAverage = calculateAverage(props.sales.map(sale => sale.totalSale));
  const cigaretteAverage = calculateAverage(props.sales.map(sale => sale.ciggaratteAmount));
  const pouchAverage = calculateAverage(props.sales.map(sale => sale.pouchAmount));
  const coldCoffeeAverage = calculateAverage(props.sales.map(sale => sale.coldCoffeeAmount));

  return (
    <div className="container mt-4 p-4" style={{ textAlign: "center", margin: "5px", padding: "10px" }}>
      <h3 className='p-3'>Average Daily Sales</h3>
      <Table striped bordered hover responsive>
        <thead >
          <tr >
            <th>Item</th>
            <th>Average Per Day</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Tea (Chai)</td>
            <td>{(chaiAverage / 10).toFixed(2)}</td> {/* Adjusting for specific logic */}
          </tr>
          <tr>
            <td>Bottle</td>
            <td>{(bottleAverage / 10).toFixed(2)}</td> {/* Adjusting for specific logic */}
          </tr>
          <tr>
            <td>Biscuit</td>
            <td>{(biscuitAverage / 5).toFixed(2)}</td> {/* Adjusting for specific logic */}
          </tr>
          <tr>
            <td>Total Sale</td>
            <td>{totalSaleAverage}</td>
          </tr>
          <tr>
            <td>Cigarette</td>
            <td>{cigaretteAverage}</td>
          </tr>
          <tr>
            <td>Pouch</td>
            <td>{pouchAverage}</td>
          </tr>
          <tr>
            <td>Cold Coffee</td>
            <td>{(coldCoffeeAverage / 20).toFixed(2)}</td> {/* Adjusting for specific logic */}
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default AverageSalesDataForDate;
