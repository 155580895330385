import React from 'react'
import { Table } from 'react-bootstrap';


  const AverageSalesData = (props) => {

    if (!props.sales) {
        return <div>Loading...</div>; // You can show a loading state or an error message
      }
    // Function to calculate average sales per day for each item
    const calculateAverage = (itemData) => {
      const totalSales = Object.values(itemData).reduce((sum, value) => sum + value, 0);
      const totalDays = Object.keys(itemData).length;
      return (totalSales / totalDays).toFixed(2);
    };
  
  console.log(" props . sales -" ,props.sales)
  
    // Extract item sales from props
    const chaiAverage = calculateAverage(props.sales.chaiTotal);
    const bottleAverage = calculateAverage(props.sales.bottleAmount);
    const biscuitAverage = calculateAverage(props.sales.biscuitAmount);
    const totalSaleAverage = calculateAverage(props.sales.totalSale);
    const cigaretteAverage = calculateAverage(props.sales.ciggaratteAmount);
    const pouchAverage = calculateAverage(props.sales.pouchAmount);
    const coldCoffeeAverage = calculateAverage(props.sales.coldCoffeeAmount);
  
    return (
      <div className="container mt-4 p-4" style={{ textAlign: "center", margin: "5px", padding: "10px" }}>
        <h3 className='p-3'>Average Daily Sales</h3>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Item</th>
              <th>Average Per Day</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Tea (Chai)</td>
              <td>{chaiAverage/10}</td>
            </tr>
            <tr>
              <td>Bottle</td>
              <td>{bottleAverage/10}</td>
            </tr>
            <tr>
              <td>Biscuit</td>
              <td>{biscuitAverage/5}</td>
            </tr>
            <tr>
              <td>Total Sale</td>
              <td>{totalSaleAverage}</td>
            </tr>
            <tr>
              <td>Cigarette</td>
              <td>{cigaretteAverage}</td>
            </tr>
            <tr>
              <td>Pouch</td>
              <td>{pouchAverage}</td>
            </tr>
            <tr>
              <td>Cold Coffee</td>
              <td>{coldCoffeeAverage/20}</td>
            </tr>
          </tbody>
        </Table>
      </div>

    );
  };
  


export default AverageSalesData
